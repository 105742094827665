.map-container {
  width: 100%;
  height: 100vh;
  z-index: 0;
}
.tooltipold {
  font-size: 12px;
  font-weight: 700;
}
.tooltip {
  pointer-events: none;
  position: absolute;
  z-index: 9;
  font-size: 12px;
  padding: 8px;
  background: #000;
  color: #fff;
  min-width: 160px;
  max-height: 240px;
  overflow-y: hidden;
}

.splash-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background-color: #000;
}
.site-layout .site-layout-background {
  background: #fff;
}
.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
}
[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
